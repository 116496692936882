"use client";
import React from "react";
import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useTranslate } from "@/components/base/providers/TranslateProv";
import Link from "next/link";
import { useGetRecommendedAdsQuery } from "@/redux/features/api/home/homeApiSlice";
import Loader from "@/components/shared/Loader";

interface AdI {
  id: number;
  link: {
    ar: string;
    en: string;
  };
  image: {
    ar: string;
    en: string;
  };
}

const Index = () => {
  const { locale } = useTranslate();
  const lang: "ar" | "en" = locale as "ar" | "en";

  const { data, isLoading } = useGetRecommendedAdsQuery({});
  const ads = data?.data || [];

  // Description text based on locale
  const adDescription = {
    ar: "اطلع على اهم الاخبار عن الجامعات والتعليم العالي الخاص والآهلي في مصر",
    en: "Check out the latest news about universities and private and public higher education in Egypt.",
  };

  return (
    <div className="bg-white">
      <div className="container pt-10">
        <p className="text-25 text-darkText font-[600] text-center mb-5">
          {locale === "ar" ? "اعلان هام" : "Important Ad"}
        </p>
        <p className="text-20 text-descriptionText text-center max-w-[723px] mx-auto mb-10">
          {adDescription[lang]}
        </p>
        {isLoading ? (
          <div className="flex justify-center items-center h-[300px]">
            <Loader />
          </div>
        ) : (
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={10}
            slidesPerView={1}
            autoplay={{ delay: 2000 }}
            pagination={{
              clickable: true,
              renderBullet(index, className) {
                return `<span class="${className} !rounded-[10px] !top-2 !bg-primaryColor !w-[45px] !h-[6px] transition"></span>`;
              },
            }}
            dir="rtl"
            className="!pb-16"
          >
            {ads?.map((ad: AdI, index: number) => (
              <SwiperSlide key={index}>
                <Link
                  href={ad.link[lang]}
                  target="_blank"
                  className="pt-5 pr-5 pb-5 pl-0 lg:p-0 rounded-[30px] relative overflow-hidden"
                >
                  <Image
                    src={ad.image[lang]}
                    alt={ad.link[lang]}
                    className="w-full h-auto max-h-[400px] object-cover rounded-[10px]"
                    width={100}
                    height={400}
                  />
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default Index;

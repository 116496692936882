"use client";
import React from "react";
// import stripe from "@/public/images/stripe.svg";
// import sponser1 from "@/public/images/sponser1.svg";
import sponser2 from "@/public/images/rascoda.svg";
import sponser3 from "@/public/images/sponser3.svg";
import sponser4 from "@/public/images/sponser4.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Image from "next/image";
import { useTranslate } from "@/components/base/providers/TranslateProv";
const Index = () => {
  const { locale } = useTranslate();
  return (
    <div className=" container py-[40px] bg-white ">
      <p className=" text-25 text-darkText text-center mb-10">
        {locale === "ar" ? "شركاء نجاح" : "Success Partners"}
      </p>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={10}
        slidesPerView={"auto"}
        breakpoints={{
          370: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          1280: {
            slidesPerView: 6,
            spaceBetween: 10,
          },
        }}
        autoplay={{ delay: 2000 }}
        dir="rtl"
        centerInsufficientSlides={true}
        className=" !pb-16 "
      >
        {/* <SwiperSlide>
          <Image
            src={sponser1}
            alt="stipe"
          />
        </SwiperSlide> */}
        <SwiperSlide>
          <div className="w-full flex justify-center items-center">
            <Image src={sponser2} alt="stipe" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="w-full flex justify-center items-center">
            <Image src={sponser3} alt="stipe" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="w-full flex justify-center items-center">
            <Image src={sponser4} alt="stipe" />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Index;

"use client";
import React from "react";
import Image from "next/image";
import heroImage from "@/public/images/heroImage.png";
import mainBg from "@/public/images/mainBg.png";
import { useTranslate } from "@/components/base/providers/TranslateProv";
import MainSearchComponent from "@/components/shared/SearchAndFilter/MainSearchComponent";

const Index = () => {
  const { t, locale } = useTranslate("home");

  const stats = [
    { value: "100K+", label: t.studentsWithUs },
    { value: "100+", label: t.existingUniversities },
    { value: "1500+", label: t.existingColleges },
    { value: "300+", label: t.existingSpecializations },
  ];

  return (
    <div
      className="relative"
      style={{
        backgroundImage: `url(${mainBg.src})`,
        backgroundSize: "cover",
        backgroundPositionY: "top",
        backgroundPositionX: `${locale === "ar" ? "left" : "right"}`,
      }}
    >
      {/* Background image only on large screens */}
      <div
        className="hidden xl:block bg-no-repeat h-full w-full absolute inset-0 z-[0]"
        style={{
          backgroundImage: `url(${heroImage.src})`,
          backgroundSize: "contain",
          backgroundPositionY: "top",
          backgroundPositionX: `${locale === "ar" ? "left" : "right"}`,
        }}
      />

      <div className="container mx-auto flex flex-col gap-5 h-full justify-center py-16">
        <h1 className="text-2xl md:text-3xl text-primaryColor font-medium">
          {t.welcomeMessage}
        </h1>
        <h2 className="text-3xl md:text-4xl lg:text-5xl text-darkText font-semibold max-w-3xl mb-4">
          {t.heroTitle}
        </h2>
        <p className="text-lg md:text-xl text-descriptionText max-w-2xl">
          {t.heroDescription}
        </p>

        <div className="flex flex-wrap gap-8 md:gap-12 my-8">
          {stats.map((stat, index) => (
            <div key={index} className="text-center">
              <p className="text-3xl md:text-4xl lg:text-5xl text-darkText font-bold">
                {stat.value}
              </p>
              <p className="text-sm md:text-base text-descriptionText font-medium">
                {stat.label}
              </p>
            </div>
          ))}
        </div>
        <MainSearchComponent />
      </div>
      {/* Image element on mobile */}
      <div className="md:hidden w-full relative">
        <Image
          src={heroImage}
          alt="Hero Image"
          className="w-full h-full max-h-[450px] object-contain"
        />
      </div>
    </div>
  );
};

export default Index;

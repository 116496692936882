"use client";

import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Link from "next/link";
import capB from "@/public/images/capB.svg";
import Image from "next/image";
import UniversityCard from "@/components/shared/UniversityCard";
import { useTranslate } from "@/components/base/providers/TranslateProv";
import { useGetSelectUniversityQuery } from "@/redux/features/api/home/homeApiSlice";
import { useGetUniversityTypesQuery } from "@/redux/features/api/dropdown/dropdownSlice";
import Loader from "@/components/shared/Loader";
import { University } from "@/types/university";
import MyLink from "@/components/shared/myLink/MyLink";

interface UniversityType {
  id: number;
  name_ar: string;
  name_en: string;
}

interface IndexProps {}

type Locale = "ar" | "en";

const Index: React.FC<IndexProps> = () => {
  const { locale } = useTranslate() as { locale: Locale };
  const [activeFilter, setActiveFilter] = useState<string>("all");

  const { data, isLoading, error, isFetching } = useGetSelectUniversityQuery({
    lang: locale,
    university_type_id: activeFilter !== "all" ? activeFilter : undefined,
  });

  const { data: typeData, isLoading: typeLoading } = useGetUniversityTypesQuery(
    { lang: locale }
  );

  const renderError = () => (
    <div className="flex justify-center items-center h-[300px]">
      <p className="text-center text-20 text-red-500 font-[600]">
        {locale === "ar" ? "خطأ في جلب البيانات." : "Error fetching data."}
      </p>
    </div>
  );

  const renderNoData = () => (
    <div className="flex justify-center items-center h-[300px]">
      <p className="text-center text-20 text-gray-500 font-[600]">
        {locale === "ar" ? "لا توجد بيانات متاحة." : "No data available."}
      </p>
    </div>
  );

  const universities = data?.data as University[] | undefined;
  const universityTypes: UniversityType[] = typeData?.data || [];

  const handleFilterChange = (filterId: string) => {
    setActiveFilter(filterId);
  };

  return (
    <div className="bg-[#F8F9FA]">
      <div className="container py-[100px]">
        <p className="text-20 text-primaryColor w-fit mx-auto py-1 px-7 border border-primaryColor rounded-full mb-2 flex items-center gap-3">
          <Image src={capB} alt="capB" />
          {locale === "ar" ? "الجامعات" : "Universities"}
        </p>
        <p className="text-25 text-darkText font-[600] text-center mb-5">
          {locale === "ar" ? "اختار جامعتك" : "Choose Your University"}
        </p>
        <p className="text-20 text-descriptionText text-center max-w-[723px] mx-auto mb-10">
          {locale === "ar"
            ? "آكثر الجامعات بحثا على الموقع لعام افضل الجاامعات لعام 2024 الموصي بها والمعتمده من قبل الطلاب"
            : "Most searched universities on the website for the best universities of 2024, recommended and approved by students"}
        </p>
        <div className="bg-white rounded-[10px] py-2 flex items-center justify-center gap-5 my-10 flex-wrap">
          <button
            className={`text-18 font-[500] rounded-[10px] py-2 px-5 ${
              activeFilter === "all"
                ? "text-white bg-primaryColor"
                : "text-darkText bg-white"
            }`}
            onClick={() => handleFilterChange("all")}
          >
            {locale === "ar" ? "الكل" : "All"}
          </button>
          {!typeLoading &&
            universityTypes.map((type) => (
              <button
                key={type.id}
                className={`text-18 font-[500] rounded-[10px] py-2 px-5 ${
                  activeFilter === type.id.toString()
                    ? "text-white bg-primaryColor"
                    : "text-darkText bg-white"
                }`}
                onClick={() => handleFilterChange(type.id.toString())}
              >
                {type[`name_${locale}`]}
              </button>
            ))}
        </div>

        {isLoading || isFetching ? (
          <Loader />
        ) : error ? (
          renderError()
        ) : universities && universities.length > 0 ? (
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={10}
            slidesPerView={"auto"}
            breakpoints={{
              370: { slidesPerView: 1, spaceBetween: 10 },
              768: { slidesPerView: 2, spaceBetween: 10 },
              1280: { slidesPerView: 3, spaceBetween: 10 },
            }}
            autoplay={{ delay: 2000 }}
            pagination={{
              clickable: true,
              renderBullet: (index: number, className: string) =>
                `<span class="${className} !rounded-[10px] !top-2 !bg-[#141522] !w-[45px] !h-[6px] transition"></span>`,
            }}
            dir={locale === "ar" ? "rtl" : "ltr"}
            className="!pb-16"
          >
            {universities.map((university: University) => (
              <SwiperSlide key={university.id} className="!h-auto">
                <UniversityCard item={university} locale={locale} />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          renderNoData()
        )}

        <MyLink
          href={`/universities?university_type=${activeFilter}`}
          className="text-16 text-primaryColor w-fit mx-auto py-2 px-7 rounded-[10px] border border-primaryColor block mt-5"
        >
          {locale === "ar" ? "عرض الكل" : "View All"}
        </MyLink>
      </div>
    </div>
  );
};

export default Index;

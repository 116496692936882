"use client";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Image from "next/image";
import time from "@/public/images/time.svg";
import view from "@/public/images/view.svg";
import locationGrey from "@/public/images/locationGrey.svg";
import calendar from "@/public/images/calendar.svg";
import facultyExist from "@/public/images/facultyExist.svg";
import blackCap from "@/public/images/blackCap.svg";
import heart from "@/public/images/heart.svg";
import capB from "@/public/images/capB.svg";
import fire from "@/public/images/fire.svg";
import Link from "next/link";
import { University } from "@/types/university";
import MyLink from "../myLink/MyLink";

interface UniversityCardProps {
  item?: University;
  locale: "ar" | "en";
}

const UniversityCard: React.FC<UniversityCardProps> = ({ item, locale }) => {
  return (
    <div className="w-full min-h-full h-full relative rounded-[10px] bg-white p-4 flex flex-col justify-between gap-4 border border-[#EBEBEB]">
      <div className="absolute  top-8 z-40 flex items-center md:start-10   gap-2">
        {item?.nominate_it === 1 && (
          <div className="bg-white py-2 px-2 md:px-5 rounded-full flex items-center gap-2">
            <Image src={capB} alt="capB" />
            <p className="text-[10px] md:text-12 text-darkText">
              {locale === "ar" ? "نرشحها" : "Recommended"}
            </p>
          </div>
        )}
        {item?.trending_now === 1 && (
          <div className="bg-white py-2 px-2 md:px-5 rounded-full flex items-center gap-2">
            <Image src={fire} alt="fire" />
            <p className="text-[10px] md:text-12 text-darkText">
              {locale === "ar" ? "رائج الان" : "Trending Now"}
            </p>
          </div>
        )}
      </div>

      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={10}
        slidesPerView={1}
        autoplay={{ delay: 3000 }}
        pagination={{
          clickable: true,
          renderBullet: (index: number, className: string) =>
            `<span class="${className} !rounded-[10px] !top-2 !bg-primaryColor !w-[30px] !h-[6px] transition"></span>`,
        }}
        dir="rtl"
        className="!pb-16 w-full h-[250px]"
      >
        {item?.images?.map((img, index) => (
          <SwiperSlide key={index}>
            {" "}
            <div className="relative w-full h-full">
              {" "}
              <Image
                src={img.image}
                alt={`University Image ${index + 1}`}
                className="rounded-[10px] object-cover"
                fill
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <div className="flex items-center gap-2">
            <Image src={time} alt="time" />
            <p className="text-14 text-descriptionText text-nowrap text-[13px] ">
              {item?.created_at.slice(0, 10)}
            </p>
          </div>

          <div className="flex items-center gap-2">
            <Image src={view} alt="view" />
            <p className="text-14 text-descriptionText text-nowrap text-[13px]">
              {item?.viewing_number} {locale === "ar" ? "مشاهدة" : "views"}
            </p>
          </div>
        </div>
        <p className="text-14 text-darkText font-[500] border border-[#EBEBEB] py-2 px-4 rounded-full whitespace-nowrap">
          {item?.type[`name_${locale}`]}
        </p>
      </div>

      <div className="flex items-start gap-4">
        <div className="shrink-0 bg-white rounded-[10px] border border-[#ebebeb] justify-center items-center gap-2.5 inline-flex">
          <Image
            src={item?.image || "#"}
            alt={item?.name[locale] || ""}
            width={96}
            height={96}
            className="w-24 h-24 object-cover"
          />
        </div>
        <div>
          <p className="text-20 text-darkText font-[500] line-clamp-1">
            {item?.name[locale]}
          </p>
          <div className="flex items-start gap-3">
            <Image src={locationGrey} alt="locationGrey" />
            <p className="text-16 text-descriptionText line-clamp-1">
              {item?.map_links && item?.map_links?.title[locale]}
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-3">
        <div className="bg-[#F8F9FA] rounded-[10px] p-2 flex flex-col items-center justify-center gap-3">
          <Image src={calendar} alt="calendar" />
          <p className="text-14 text-darkText">
            {locale === "ar" ? "تأسيس الجامعه" : "Establishment Year"}
          </p>
          <p className="text-14 text-[#ACB5BB] font-[600]">
            {item?.founding_date}
          </p>
        </div>
        <div className="bg-[#F8F9FA] rounded-[10px] p-2 flex flex-col items-center justify-center gap-3">
          <Image src={facultyExist} alt="faculties" />
          <p className="text-14 text-darkText">
            {locale === "ar" ? "الكليات" : "Faculties"}
          </p>
          <p className="text-14 text-[#ACB5BB] font-[600]">
            + {item?.faculties_count}
          </p>
        </div>
        <div className="bg-[#F8F9FA] rounded-[10px] p-2 flex flex-col items-center justify-center gap-3">
          <Image src={blackCap} alt="specializations" />
          <p className="text-14 text-darkText">
            {locale === "ar" ? "تخصص" : "Programs"}
          </p>
          <p className="text-14 text-[#ACB5BB] font-[600]">
            {item?.specialization_count}
          </p>
        </div>
      </div>

      <div className="flex items-center gap-3">
        <MyLink
          href={`/universities/${item?.id}`}
          className="text-20 text-white bg-primaryColor h-[58px] grow flex justify-center items-center rounded-[10px]"
        >
          {locale === "ar" ? "عرض تفاصيل" : "View Details"}
        </MyLink>
        <div className="h-[58px] rounded-[15px] w-[58px] border border-[#EBEBEB] flex items-center justify-center cursor-pointer">
          <Image src={heart} alt="heart" />
        </div>
      </div>
    </div>
  );
};

export default UniversityCard;

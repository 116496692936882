"use client";
import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Link from "next/link";
import FacultyCard from "../../shared/FacultyCard";
import capB from "@/public/images/capB.svg";
import Image from "next/image";
import { useGetAllFacultiesQuery } from "@/redux/features/api/home/homeApiSlice";
import { useTranslate } from "@/components/base/providers/TranslateProv";
import Loader from "@/components/shared/Loader";
import MyLink from "@/components/shared/myLink/MyLink";
import { Faculty } from "@/types/Faculty";

const Index: React.FC = () => {
  const { locale } = useTranslate();
  const lang: "ar" | "en" = locale as "ar" | "en";

  const { data, isLoading, error } = useGetAllFacultiesQuery({
    lang,
  });

  const faculties: Faculty[] = data?.data || [];

  return (
    <div className="bg-white">
      <div className="container py-[100px]">
        <p className="text-20 text-primaryColor w-fit mx-auto py-1 px-7 border border-primaryColor rounded-full mb-2 flex items-center gap-3">
          <Image
            src={capB}
            alt="capB"
          />
          {lang === "ar" ? "الكليات" : "Colleges"}
        </p>
        <p className="text-25 text-darkText font-[600] text-center mb-5">
          {lang === "ar" ? "الكليات الموجودة" : "Available Colleges"}
        </p>
        <p className="text-20 text-[#6C7278] text-center max-w-[723px] mx-auto mb-10">
          {lang === "ar"
            ? "افضل الكليات لعام 2024 الموصي بها والمعتمده من قبل الطلاب"
            : "The best Colleges of 2024 recommended and accredited by students."}
        </p>
        {isLoading && (
          <div className="flex justify-center items-center h-[300px]">
            <Loader />
          </div>
        )}
        {error && (
          <div className="flex justify-center items-center h-[300px]">
            <p className="text-center text-20 text-red-500 font-[600]">
              {lang === "ar" ? "خطأ في جلب البيانات." : "Error fetching data."}
            </p>
          </div>
        )}
        {!isLoading && !error && (
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={10}
            slidesPerView={"auto"}
            breakpoints={{
              370: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
            autoplay={{ delay: 2000 }}
            pagination={{
              clickable: true,
              renderBullet(index, className) {
                return `<span class="${className} !rounded-[10px] !top-2 !bg-primaryColor !w-[45px] !h-[6px] transition"></span>`;
              },
            }}
            dir={lang === "ar" ? "rtl" : "ltr"}
            className="!pb-16"
          >
            {faculties.map((faculty) => (
              <SwiperSlide key={faculty.id}>
                <FacultyCard
                  faculty={faculty}
                  locale={lang}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        <MyLink
          href={"/general-faculties"}
          className="text-16 text-primaryColor w-fit mx-auto py-2 px-7 rounded-[10px] border border-primaryColor block mt-5"
        >
          {lang === "ar" ? "عرض الكل" : "View All"}
        </MyLink>
      </div>
    </div>
  );
};

export default Index;

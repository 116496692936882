import(/* webpackMode: "eager" */ "/var/www/front/safiruni/front/components/Home/Announcement/index.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/front/safiruni/front/components/Home/Articles/index.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/front/safiruni/front/components/Home/Faculties/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/front/safiruni/front/components/Home/HeroSection/index.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/front/safiruni/front/components/Home/ImportantUniversities/index.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/front/safiruni/front/components/Home/Opinion/index.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/front/safiruni/front/components/Home/PartnerSuccess/index.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/front/safiruni/front/components/Home/Universities/index.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/front/safiruni/front/components/shared/AboutUs/index.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/front/safiruni/front/components/shared/FAQ/index.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/front/safiruni/front/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/var/www/front/safiruni/front/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");

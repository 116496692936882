"use client";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import ArticleCard from "../../shared/ArticleCard";
import MyLink from "@/components/shared/myLink/MyLink";
import { useTranslate } from "@/components/base/providers/TranslateProv";
import { useGetArticlesQuery } from "@/redux/features/api/home/homeApiSlice";
import Loader from "@/components/shared/Loader";
import { Blog } from "@/types/blog";

const Index = () => {
  const { locale } = useTranslate();
  const lang: "ar" | "en" = locale as "ar" | "en";

  const { data, isLoading, error } = useGetArticlesQuery({
    lang,
  });

  const articles = data?.data || [];

  const translations = {
    ar: {
      sectionTitle: "المقالات",
      header: "قرائة افضل المدونات لدينا",
      description:
        "اكتشفوا أفضل المدونات لدينا، حيث نقدم لكم محتوى مميز يغطي مواضيع تعليمية وثقافية متنوعة تساعدكم في تطوير معرفتكم وتحقيق أهدافكم.",
      viewAll: "عرض الكل",
    },
    en: {
      sectionTitle: "Articles",
      header: "Read Our Best Blogs",
      description:
        "Explore our top blogs, where we offer you exceptional content covering a variety of educational and cultural topics that help you expand your knowledge and achieve your goals.",
      viewAll: "View All",
    },
  };

  const t = translations[lang];

  return (
    <div className="bg-white">
      <div className="container py-[100px]">
        <p className="text-20 text-primaryColor w-fit mx-auto py-1 px-7 border border-primaryColor rounded-full mb-2">
          {t.sectionTitle}
        </p>
        <p className="text-25 text-darkText font-[600] text-center mb-5">
          {t.header}
        </p>
        <p className="text-20 text-[#6C7278] text-center max-w-[723px] mx-auto mb-10">
          {t.description}
        </p>

        {isLoading && (
          <div className="flex justify-center items-center h-[300px]">
            <Loader />
          </div>
        )}
        {error && (
          <div className="flex justify-center items-center h-[300px]">
            <p className="text-center text-20 text-red-500 font-[600]">
              {lang === "ar" ? "خطأ في جلب البيانات." : "Error fetching data."}
            </p>
          </div>
        )}
        {!isLoading && !error && (
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={10}
            slidesPerView={"auto"}
            breakpoints={{
              370: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
            autoplay={{ delay: 2000 }}
            pagination={{
              clickable: true,
              renderBullet(index, className) {
                return `<span class="${className} !rounded-[10px] !top-2 !bg-primaryColor !w-[45px] !h-[6px] transition"></span>`;
              },
            }}
            dir={locale === "ar" ? "rtl" : "ltr"}
            className="!pb-16"
          >
            {articles.map((blog: Blog, index: number) => (
              <SwiperSlide
                key={index}
                className="!h-auto"
              >
                <ArticleCard blog={blog} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        <MyLink
          href={"/articles"}
          className="text-16 text-primaryColor w-fit mx-auto py-2 px-7 rounded-[10px] border border-primaryColor block mt-5"
        >
          {t.viewAll}
        </MyLink>
      </div>
    </div>
  );
};

export default Index;

"use client";

import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Image from "next/image";
import Link from "next/link";
import capB from "@/public/images/capW.svg";
import UniversityCard from "@/components/shared/UniversityCard";
import { useGetAllUniversitiesQuery } from "@/redux/features/api/home/homeApiSlice";
import { useTranslate } from "@/components/base/providers/TranslateProv";
import Loader from "@/components/shared/Loader";
import MyLink from "@/components/shared/myLink/MyLink";
import { University } from "@/types/university";

interface IndexProps {}

const Index: React.FC<IndexProps> = () => {
  const { locale } = useTranslate();

  const { data, isLoading, error } = useGetAllUniversitiesQuery({
    lang: locale,
  });

  const renderError = () => (
    <div className="flex justify-center items-center h-[300px]">
      <p className="text-center text-20 text-red-500 font-[600]">
        {locale === "ar" ? "خطأ في جلب البيانات." : "Error fetching data."}
      </p>
    </div>
  );

  const universities = data?.data as University[] | undefined;

  return (
    <div className="bg-primaryColor ">
      <div className="container py-[100px]">
        <p className="text-20 text-white w-fit mx-auto py-1 px-7 border border-white rounded-full mb-2 flex items-center gap-3">
          <Image src={capB} alt="capB" />
          {locale === "ar" ? "الجامعات الموصي بها" : "Recommended Universities"}
        </p>
        <p className="text-25 text-white font-[600] text-center mb-5">
          {locale === "ar" ? "الجامعات الموصي بها" : "Recommended Universities"}
        </p>
        <p className="text-20 text-[#BEC8FF] text-center max-w-[723px] mx-auto mb-10">
          {locale === "ar"
            ? "آكثر الجامعات بحثا على الموقع لعام ٢٠٢٤"
            : "Most searched universities on the website for 2024"}
        </p>
        {isLoading ? (
          <Loader color="white" />
        ) : error ? (
          renderError()
        ) : (
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={10}
            slidesPerView={"auto"}
            breakpoints={{
              370: { slidesPerView: 1, spaceBetween: 10 },
              768: { slidesPerView: 2, spaceBetween: 10 },
              1280: { slidesPerView: 3, spaceBetween: 10 },
            }}
            autoplay={{ delay: 2000 }}
            pagination={{
              clickable: true,
              renderBullet: (index: number, className: string) =>
                `<span class="${className} !rounded-[10px] !top-2 !bg-[#141522] !w-[45px] !h-[6px] transition"></span>`,
            }}
            dir="rtl"
            className="!pb-16"
          >
            {universities?.map((university: University) => (
              <SwiperSlide key={university.id} className="!h-auto">
                <UniversityCard item={university} locale={locale} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        <MyLink
          href={"/universities?recommended=recommended"}
          className="text-16 text-white w-fit mx-auto py-2 px-7 rounded-[10px] border border-white block mt-5"
        >
          {locale === "ar" ? "عرض الكل" : "View All"}
        </MyLink>
      </div>
    </div>
  );
};

export default Index;
